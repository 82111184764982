<template>
  <span>
    <div
      :class="`topbar flex-row ${topbarhide ? 'tbhide' : 'tbshow'}`"
      v-if="barclass == 'closethis'"
      :style="`margin-top:${tb ? '0px' : '-140px'}`"
    >
      <div class="toplan">
        <div class="image-wrapper_1 flex-col justify-between w-logo wllogo">
          <img
            @click="goIndex"
            class="w-logo-img"
            referrerpolicy="no-referrer"
            src="../assets/limgs/logo.png"
          />
        </div>

        <div class="blinks justify-end" v-if="w750 == 0">
          <template v-for="(item, index) in blinks">
            <template v-if="index < blinks.length">
              <span
                @mouseenter="
                  () => {
                    blinkshandleHover(index, item.id);
                  }
                "
                @touchstart="
                  () => {
                    blinkshandleHover(index, item.id);
                  }
                "
                v-if="bselectindex != index"
                class="item flex-col justify-between"
                :key="index"
              >
                <span class="itemtext">{{ item.title }}</span>
              </span>
              <span
                @mouseleave="blinkshandleLeave"
                @click="
                  () => {
                    goSelect(item.id);
                  }
                "
                v-if="bselectindex == index"
                class="itemf flex-col justify-between"
                :key="index"
              >
                <span class="itemtext">{{ item.title }}</span>

                <div class="lined">
                  <div class="line"></div>
                </div>
              </span>
            </template>
          </template>

          <template v-if="selectlag">
            <!-- <span
              :class="`${
                lag == 'en' ? 'item' : 'itemf'
              } flex-col justify-between`"
              @click="
                () => {
                  changelag('');
                }
              "
              v-if="lag == 'en'"
            >
              <span class="itemtext">中</span>
            </span>
            <span
              :class="`${
                lag == 'en' ? 'item' : 'itemf'
              } flex-col justify-between`"
              v-else
            >
              <span class="itemtext" style="opacity: 0.3">中</span>
            </span>

            <span
              class="item flex-col justify-between"
              style="margin-left: 5px"
            >
              <span class="itemtext">|</span>
            </span>

            <span
              :class="` ${
                lag == 'en' ? 'itemf' : 'item'
              }  flex-col justify-between`"
              style="margin-left: 5px"
              @click="
                () => {
                  changelag('en');
                }
              "
              v-if="lag == ''"
            >
              <span class="itemtext">EN</span>
            </span>
            <span
              :class="` ${
                lag == 'en' ? 'itemf' : 'item'
              }  flex-col justify-between`"
              style="margin-left: 5px"
              v-else
            >
              <span class="itemtext" style="opacity: 0.3">EN</span>
            </span> -->
            <span :class="` ${'itemf'}  flex-col justify-between`"
              style="margin-left: 40px" @click="()=>{changelag('')}">
              <span class="itemtext" style="opacity:.3;"><a-icon type="global" /> English</span>
            </span>

          </template>

          <span
            class="item flex-col justify-between"
            v-if="basedata.loginshow == 'true' || basedata.dev"
          >
            <div
              class="icon-login"
              v-if="memberdata.member_id == ''"
              @click="openlr"
            ></div>
            <div
              class="icon-login"
              v-else
              @click="openpc"
              style="background-image: none"
            >
              <div
                v-if="memberdata.member_photo == ''"
                class="member-photo"
                :style="`background-image:url(https://cdn.moheweb.com/vdb/lweb/imgs/person.png)`"
              ></div>
              <div
                v-else
                class="member-photo"
                :style="`background-image:url(${
                  baseUrl + memberdata.member_photo
                })`"
              ></div>
            </div>
          </span>
          <span class="item flex-col justify-between" v-else>
            <div class="icon-login"></div>
          </span>
        </div>

        <div
          class="showbar"
          @click="openBardiv"
          v-if="barclass == 'closethis' && w750 == 1"
        ></div>
      </div>

      <div class="darkback" :style="{ opacity: topbarOpacity }"></div>

      <div class="lselect"></div>

      <div :class="`selectlan flex-col windowheight ${selectlanclass}`">
        <div class="selectlist" @mouseleave="closeselectlan">
          <template v-for="(item, index) in thisblinks">
            <div
              :class="` slphoto ${sid == 0 ? 'slphoto0' : ''} ${
                sid == 1 ? 'slphoto1' : ''
              }${sid == 2 ? 'slphoto2' : ''}${sid == 3 ? 'slphoto3' : ''}${
                sid == 4 ? 'slphoto4' : ''
              }`"
              :style="`background-image: url(${item.url});`"
              @click="
                () => {
                  goMindex(item.id);
                }
              "
              :key="index"
            >
              <div class="sltitle">
                <template v-if="item.id == 20 || item.id == 21">
                  {{ item.title }}
                </template>
                <template v-else>
                  <img
                    v-if="item.title != ''"
                    :src="`https://cdn.moheweb.com/vdb/lweb/imgs/title${item.id}s.png`"
                    style="
                      height: 48px;
                      widht: 400px;
                      object-fit: contain;
                      display: block;
                    "
                  />
                </template>
              </div>
              <div class="sldes">{{ item.des }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <login-reg
      :w750="w750"
      :lag="lag"
      :show="lrshow"
      :state="state"
      :type="type"
      @closelr="openlr"
      @openAM="openAM"
      @baoming="baoming"
    ></login-reg>
    <bao-ming
      :w750="w750"
      :lag="lag"
      :show="bmshow"
      :state="bmstate"
      :bmid="bmid"
      :bkind="bkind"
      @closebm="closebm"
    ></bao-ming>

    <agree-ment
      :show="amshow"
      :lag="lag"
      :kind="amkind"
      @closeAM="closeAM"
    ></agree-ment>
  </span>
</template>

<script>
import { fetchBase, checkMember, fetchMemberinfo } from "../api/index";
import $ from "jquery";
import LoginReg from "../components/LoginReg.vue";
import BaoMing from "../components/BaoMing.vue";
import AgreeMent from "../components/AgreeMent.vue";
import global from "../common";
export default {
  name: "TopA",
  components: {
    LoginReg,
    BaoMing,
    AgreeMent,
  },
  props: {
    selectlag: {
      type: Number,
      default: 1,
    },
    bmstate: {
      type: Boolean,
      default: false,
    },
    bmshow: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0,
    },
    bkind: String,
    bmid: String,
    msg: String,
    kind: String,
    topbarhide: Number,
    tb: Boolean,
    w750: Number,
    topbarOpacity: Number,
    lag: {
      type: String,
      default: "",
    },
  },
  // watch: {
  //   lag: function (newVal, oldVal) {
  //     this.tlag = newVal; //这样就能在method使用props
  //     console.log("Watch：", this.tlag);
  //   },
  //   kind: function (newVal, oldVal) {
  //     this.tkind = newVal; //这样就能在method使用props
  //     console.log("Watch：", this.tkind);
  //   },
  // },
  data() {
    return {
      type: "",
      amshow: 0,
      amkind: "",

      basedata: {},
      memberdata: {},
      state: false,
      lrshow: 0,
      startsl: 0,
      selectlanclass: "",
      selectlanslhow: 1,
      thisblinks: [],
      sid: "0",
      // tkind: "",
      barclass: "closethis",
      bselectindex: -1,
      baseUrl: "",
      blinks: [
        {
          id: "0",
          title: "趣野风向",
          url: "",
          class: "jianjie",
          list: [
            {
              title: "PM.看看世界",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back00.png",
              id: "00",
            },
            {
              title: "趣野故事会",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back01.png",
              id: "01",
            },
            {
              title: "趣野视角",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back02.png",
              id: "02",
            },
          ],
        },
        {
          id: "2",
          title: "趣野好物",
          url: "",
          class: "fuwuneirong",
          list: [
            {
              title: "",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/qd.jpg",
              id: "20",
            },
          ],
        },
        {
          id: "3",
          title: "趣野SHOW",
          url: "",
          class: "anli",
          list: [
            {
              title: "现场SHOW什么？",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back30.png",
              id: "30",
            },
            {
              title: "新鲜SHOW闻",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back31.png",
              id: "31",
            },
            {
              title: "想来SHOW一下么？",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back33.png",
              id: "33",
            },
            {
              title: "Ta们在SHOW",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back32.png",
              id: "32",
            },
          ],
        },
        {
          id: "4",
          title: "趣野CLUB",
          url: "",
          class: "lianxiwomen",
          list: [
            {
              title: "趣野人集合",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back40.png",
              id: "40",
            },
            {
              title: "精彩回放",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back41.png",
              id: "41",
            },
          ],
        },
        {
          id: "1",
          title: "趣野伙伴",
          url: "",
          class: "huoban",
          list: [
            // {
            //   title: "",
            //   des: "",
            //   url: "https://cdn.moheweb.com/vdb/lweb/imgs/qd.jpg",
            //   id: "10",
            // },
            {
              title: "先锋玩家",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back10.png",
              id: "10",
            },
            {
              title: "合作品牌",
              des: "",
              url: "https://cdn.moheweb.com/vdb/lweb/imgs/back11.png",
              id: "11",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.startsl = 1;
    }, 500);
    this.baseUrl = global.baseUrl;
    this.memberdata = global.getMemberinfo();
    // console.log("TopA memberdata:", this.memberdata);
    this.fetchb();
    // this.resetfetchMemberinfo();
  },
  methods: {
    // resetfetchMemberinfo() {
    //   let that = this;
    //   let params = {};
    //   console.log("params:", params);
    //   fetchMemberinfo(params).then((res) => {
    //       let data = res.results;
    //       global.setMemberInfo(data);
    //       console.log("that.memberdata:", that.memberdata);
    //   });
    // },

    openAM(kind) {
      this.amkind = kind;
      this.amshow = 1;
    },
    closeAM() {
      this.amshow = 0;
    },
    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        that.loading = false;
        that.basedata = res.results;
        global.setbaseinfo(that.basedata);
        that.basedata = global.getbaseinfo();
      });

      checkMember(params).then((res) => {
        let message = res.results.message;
        if (message == 0) {
          global.clearMemberInfo();
          that.memberdata = global.getMemberinfo();
        }
      });
    },
    openbm() {
      this.bmshow = 1;
      this.bmstate = true;
    },
    closebm() {
      console.log("closebm");
      this.$emit("bmclose");
    },
    openlr(type = "") {
      console.log("openlr type:", type);
      if (this.lrshow == 0) {
        this.lrshow = 1;
        this.state = true;
        this.type = type;
        this.$emit("rlstate", 1);
      } else {
        this.lrshow = 0;
        this.type = type;
        this.$emit("rlstate", 0);
      }
      console.log("lrshow:", this.lrshow);
      console.log("state:", this.state);
    },
    baoming() {
      this.memberdata = global.getMemberinfo();
      this.lrshow = 0;
      this.$emit("baoming");
    },
    openpc() {
      this.memberdata = global.getMemberinfo();
      console.log("this.memberdata.isEmpty:", this.memberdata.isEmpty);
      if (this.memberdata.isEmpty > 0) {
        this.openlr();
        return false;
      }
      window.location.href = `#/pcenter/${this.lag}`;
    },
    closeselectlan() {
      this.selectlanslhow = 0;
      this.selectlanclass = "slhide";
    },
    goMindex(id2) {
      let url = "";
      let id = this.sid;

      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      console.log("id:", id, "url:", url);

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }

      this.selectlanslhow = 0;
      this.selectlanclass = "slhide";
    },

    goIndex() {
      if (this.kind == "index") {
        location.reload();
      } else {
        this.$router.push(`/index/${this.lag}`);
      }
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },

    changelag(lag) {
      this.$router.replace("/indexen");
      return false;

      // if (lag == "en") {
      //   this.$router.replace("index/en");
      //   setTimeout(() => {
      //     location.reload();
      //   }, 100);
      // } else {
      //   this.$router.replace("/index");
      //   setTimeout(() => {
      //     location.reload();
      //   }, 100);
      // }

      let path = this.$route.path;

      console.log("lag:", lag);

      let paths = path.split("/");

      console.log("paths:", paths);

      path = "";

      for (let i = 1; i < paths.length; i++) {
        console.log(i, paths.length - 1);
        if (i == paths.length - 1) {
          path += lag;
        } else {
          path += paths[i] + "/";
        }
      }

      path = "/" + path;

      console.log("path:", path); // 输出当前URL的路径部分

      this.$router.replace(path);
    },

    blinkshandleHover(index, id) {
      if (this.startsl == 0) {
        return false;
      }
      this.bselectindex = index;
      this.sid = id;

      let tblinks = this.blinks.filter((item) => {
        return item.id == this.sid;
      })[0];

      console.log("tblinks:", tblinks);

      this.thisblinks = tblinks.list;

      console.log("this.thisblinks:", this.thisblinks);

      this.selectlanslhow = 1;

      this.selectlanclass = "slshow";

      console.log("this.selectlanslhow:", this.selectlanslhow);

      // setTimeout(() => {

      let pageHeight = window.innerHeight;

      $(".selectlan").css({ height: `${pageHeight}px` });

      // }, 100);
    },

    goSelect(id) {
      // this.$router.push(`/sindex/${index}/${this.lag}`);

      let url = "";
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      console.log("url:", url);

      // alert(url);

      if (url != "") {
        this.$router.push(`/${url}/0/${this.lag}`);
      }
    },
    goSelectb(id, id2) {
      let url = "";
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }

      // this.$router.push(`/sindex/${index}/${this.lag}`);
    },
  },
};
</script>

<style scoped></style>
